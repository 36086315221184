import { Callout } from '../../../components/Callout'
import { useRootLoader } from '../../../root'

type Props = {
  children?: React.ReactNode
}

export function StripeTestModeWarning({ children }: Props) {
  let { config } = useRootLoader()

  if (config.mode === 'production') {
    return null
  }

  return (
    <Callout variant="warning">
      We're running Stripe in{' '}
      <a
        href="https://stripe.com/docs/test-mode?locale=en-GB"
        target="_blank"
        rel="noreferrer"
      >
        test mode
      </a>{' '}
      to test our integration without making actual charges or payments.{' '}
      {children}
    </Callout>
  )
}
